<template>
  <div
    id="newEditTaskModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="newEditTaskModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <ValidationObserver ref="newEditTaskForm" v-slot="{ handleSubmit }" slim>
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="newEditTaskModalLabel" class="modal-title">
              {{ modalTitle }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="resetForm(true)"
            ></button>
          </div>
          <div class="modal-body">
            <ValidationProvider
              tag="div"
              rules="required"
              name="Title"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="title" class="col-form-label">Title:</label>
              <input
                type="text"
                v-model="newEditTask_data.title"
                id="title"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Department"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="department" class="col-form-label">Department:</label>
              <select
                v-model="newEditTask_data.department"
                name="department"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="department in departments"
                  :key="department.name"
                  :value="department.name"
                >
                  {{ department.name }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="dropdown"
              name="Priority"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="level" class="col-form-label">Priority:</label>
              <select
                v-model.number="newEditTask_data.priority"
                name="priority"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="(priority, index) in priorities"
                  :key="index"
                  :value="index"
                >
                  {{ priority.name }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Status"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="status" class="col-form-label">Status:</label>
              <select
                v-model="newEditTask_data.status"
                name="status"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="status in statuses"
                  :key="status.name"
                  :value="status.name"
                >
                  {{ status.name }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              name="Description"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="description" class="col-form-label"
                >Description:</label
              >
              <textarea
                id="description"
                class="form-control"
                rows="3"
                v-model="newEditTask_data.description"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              ></textarea>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click.prevent="resetForm(true)"
            >
              Close
            </button>
            <button
              v-if="!existingTask"
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(createTask)"
            >
              Create Task
              <loader :isLoading="isLoading"></loader>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(editTask)"
            >
              Update Task
              <loader :isLoading="isLoading"></loader>
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import { Modal } from "bootstrap";
import { getters, mutations } from "../store";
export default {
  props: {
    selectedDeparment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: null,
      isLoading: false,
      newEditTask_data: {
        title: "",
        department: "",
        priority: -1,
        status: "",
        description: "",
        isDraggable: false,
        createdBy: "",
      },
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.modal = new Modal(document.getElementById("newEditTaskModal"));
  },
  computed: {
    userLoggedIn() {
      return getters.userLoggedIn();
    },
    existingTask() {
      return "_id" in this.selectedTask;
    },
    departments() {
      return getters.tasks();
    },
    statuses() {
      return getters.statuses().filter(({ name }) => {
        return this.existingTask ? name : name !== "DONE";
      });
    },
    priorities() {
      return getters.priorities();
    },
    selectedTask() {
      return getters.selectedTask();
    },
    modalTitle() {
      return `${this.existingTask ? "Edit" : "New"} Task`;
    },
  },
  watch: {
    userLoggedIn: {
      handler({ name }) {
        this.newEditTask_data.createdBy = name;
      },
      deep: true,
      immediate: true,
    },
    selectedTask: {
      handler(selectedTask) {
        if (this.existingTask) {
          this.newEditTask_data = { ...selectedTask };
          if (selectedTask.isDraggable) {
            this.editTask();
          }
        } else {
          this.resetForm();
        }
      },
      deep: true,
    },
    selectedDeparment: {
      handler(selectedDeparment) {
        this.newEditTask_data.department = selectedDeparment;
      },
    },
  },
  methods: {
    createTask() {
      this.isLoading = true;
      this.$axios
        .post("/agenda/create", this.newEditTask_data)
        .then(({ data: { success, message, createdTask } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            this.resetForm(true);
            mutations.setTask(createdTask);
          }
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    editTask() {
      this.isLoading = true;
      this.$axios
        .patch(`/agenda/${this.newEditTask_data._id}`, this.newEditTask_data)
        .then(({ data: { success, message, updatedTask } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            mutations.updateTask(updatedTask);
          }
          this.resetForm(true);
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    showResponse(response) {
      this.modal.hide();
      mutations.showToast(response);
    },
    showModal() {
      this.modal.show();
    },
    resetForm(clearTask = false) {
      this.$nextTick(() => {
        this.newEditTask_data = {
          title: "",
          department: this.selectedDeparment,
          priority: -1,
          status: "",
          description: "",
          isDraggable: false,
          createdBy: this.userLoggedIn.name,
        };
        if (clearTask) {
          mutations.selectTask();
        }
        this.$refs.newEditTaskForm.reset();
      });
    },
  },
};
</script>

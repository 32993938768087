<template>
  <div class="modal fade" id="deleteUserModal" tabindex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="deleteUserModalLabel" class="modal-title">Delete User</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            @click.prevent="resetForm"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete <b>{{ selectedUser.name }}</b>?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="resetForm">
            Close
          </button>
          <button type="button" class="btn btn-danger" @click.prevent="deleteUser">
            Delete User
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import { Modal } from "bootstrap";
import { getters, mutations } from "../store";
export default {
  data() {
    return {
      modal: null,
      isLoading: false,
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.modal = new Modal(document.getElementById("deleteUserModal"));
  },
  computed: {
    selectedUser() {
      return getters.selectedUser();
    },
  },
  methods: {
    deleteUser() {
      this.isLoading = true;
      this.$axios
        .delete("/users/" + this.selectedUser._id)
        .then(({ data: { success, message } }) => {
          this.modal.hide();
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            mutations.deleteUser(this.selectedUser);
          }
          this.resetForm();
          mutations.showToast({ success, message });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showModal() {
      this.modal.show();
    },
    resetForm() {
      mutations.selectUser();
    },
  },
};
</script>
<template>
  <div class="agenda">
    <div class="tile">
      <!-- <div class="d-flex flex-row-reverse">
        <button
          type="button"
          class="btn btn-primary mb-2"
          @click.prevent="showModal('newEditTaskModal')"
        >
          <i class="fas fa-calendar-plus"></i> New Task
        </button>
      </div> -->
      <div class="mt-4">
        <h5 class="tile-title mb-3">Departments</h5>
        <div id="departmentsAccordion" class="accordion accordion-flush" v-if="existingDepartments">
          <div class="accordion-item" v-for="(department, index) in departments" :key="index">
            <h2 :id="`heading${index}`" class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                :data-bs-target="`#department${index}`" aria-expanded="false" :aria-controls="`#department${index}`"
                @click="selectDepartment(department.name)">
                <span>{{ department.name }}</span>
              </button>
            </h2>
            <div :id="`department${index}`" class="accordion-collapse collapse" :aria-labelledby="`heading${index}`"
              data-bs-parent="#departmentsAccordion">
              <div class="accordion-body">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-primary mb-2" @click.prevent="showModal('newEditTaskModal')">
                    <i class="fas fa-calendar-plus"></i> New Task
                  </button>
                </div>
                <div class="row">
                  <div v-drag-and-drop:options="options">
                    <ul class="d-flex align-items-start">
                      <li class="card drag-column" v-for="(status, index) in department.tasksList" :key="status.id">
                        <div class="card-header text-center">
                          <strong>{{ status.name }}</strong>
                        </div>
                        <vue-draggable-group v-model="status.items" :groups="department.tasksList" :data-id="status.id">
                          <ol class="drag-inner-list list-group list-group-numbered" :data-id="status.id"
                            :data-index="index" v-if="status.items.length > 0">
                            <li class="card text-center m-2 drag-item" v-for="task in status.items" :key="task._id"
                              :data-id="task._id" :data-department="task.department">
                              <div class="card-header p-1">
                                <div class="d-flex justify-content-between align-items-baseline">
                                  <span class="card-title fw-bold">
                                    {{ task.title }}
                                  </span>
                                  <span :class="priorities[task.priority].class">{{ priorities[task.priority].name
                                  }}</span>
                                </div>
                              </div>
                              <div class="card-body p-0">
                                <p class="card-text mt-2 p-1 description">
                                  {{ task.description }}
                                </p>
                                <div class="mb-2">
                                  <button type="button" class="btn btn-primary btn-sm me-2" @click.prevent="
                                    showModal('newEditTaskModal', task)
                                    ">
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button type="button" class="btn btn-danger btn-sm" @click.prevent="
                                    showModal('deleteTaskModal', task)
                                    ">
                                    <i class="fas fa-minus-circle"></i>
                                  </button>
                                </div>
                              </div>
                              <div class="card-footer text-muted p-1">
                                {{ task.createdBy }}
                              </div>
                            </li>
                            <!-- <li
                              class="
                                drag-item
                                list-group-item
                                d-flex
                                justify-content-between
                                align-items-start
                              "
                              v-for="task in status.items"
                              :key="task._id"
                              :data-id="task._id"
                              :data-department="task.department"
                            >
                              <div class="ms-2 me-auto">
                                <div class="fw-bold">
                                  {{ task.title }}
                                  <span
                                    :class="priorities[task.priority].class"
                                    >{{ priorities[task.priority].name }}</span
                                  >
                                </div>
                                <p class="text-wrap m-0">
                                  {{ task.description }}
                                </p>
                              </div>
                              <div>
                                <div class="mb-2">{{ task.createdAt }}</div>
                                <div class="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="
                                      showModal('newEditTaskModal', task)
                                    "
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click.prevent="
                                      showModal('deleteTaskModal', task)
                                    "
                                  >
                                    <i class="fas fa-minus-circle"></i>
                                  </button>
                                </div>
                              </div>
                            </li> -->
                          </ol>
                          <ol v-else class="drag-inner-list list-group list-group-numbered text-center"
                            :data-id="status.id" :data-index="index">
                            <div class="card border-light m-2">
                              <div class="card-body text-secondary">
                                <h5 class="card-title">No Tasks</h5>
                              </div>
                            </div>
                          </ol>
                        </vue-draggable-group>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <deleteTask ref="deleteTaskModal"></deleteTask>
      <newEditTask ref="newEditTaskModal" :selectedDeparment="selectedDeparment"></newEditTask>
    </div>
  </div>
</template>

<script>
import { getters, mutations } from "../store";
import deleteTask from "../components/deleteTask";
import newEditTask from "../components/newEditTask";
export default {
  data() {
    return {
      selectedDeparment: "",
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
        onDragend: ({ items, droptarget }) => {
          let dropAsFirst =
            droptarget.firstChild.classList.contains("item-dropzone-area");
          let selectedTask = {
            department: items[0].getAttribute("data-department"),
            _id: items[0].getAttribute("data-id"),
            updatedTaskListIndex: parseInt(
              droptarget.getAttribute("data-index")
            ),
            dropAsFirst: dropAsFirst,
          };
          mutations.updateTask(selectedTask, true);
        },
      },
    };
  },
  components: {
    deleteTask,
    newEditTask,
  },
  created() {
    this.getTasks();
  },
  computed: {
    departments() {
      return getters.tasks();
    },
    statuses() {
      return getters.statuses();
    },
    priorities() {
      return getters.priorities();
    },
    existingDepartments() {
      return this.departments.length > 0;
    },
  },
  methods: {
    getTasks() {
      this.$axios({
        url: "/agenda",
      })
        .then(({ data }) => {
          mutations.setTasks(data.tasksList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectDepartment(department) {
      this.selectedDeparment = department;
    },
    showModal(modalID, task) {
      mutations.selectTask(task);
      this.$refs[modalID].showModal();
    },
  },
};
</script>

<style lang="scss">
.drag-column {
  flex: 1;
  margin: 0 10px;
  position: relative;
  overflow: hidden;

  @media (max-width: 690px) {
    margin-bottom: 30px;
  }
}

.drag-inner-list {
  height: 85vh;
  overflow: auto;

  .list-group-item {
    border-radius: inherit;
  }

  .description {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}

.drag-item {
  margin: 10px;
  height: auto;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  &[aria-grabbed="true"] {
    background: rgb(81 140 227 / 37%);
    color: #000000;
  }
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 10rem;
  background: rgb(81 140 227 / 37%);
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  border-radius: 0.375rem;

  &:first-child {
    margin-top: 0.5rem !important;
  }
}
</style>

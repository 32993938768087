<template>
  <div
    id="newEditUserModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="newEditUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <ValidationObserver ref="newEditUserForm" v-slot="{ handleSubmit }" slim>
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="newEditUserModalLabel" class="modal-title">
              {{ modalTitle }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="resetForm(true)"
            ></button>
          </div>
          <div class="modal-body">
            <ValidationProvider
              tag="div"
              rules="required"
              name="Full Name"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="fullname" class="col-form-label">Full Name:</label>
              <input
                type="text"
                v-model="newEditUser_data.name"
                id="fullname"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required|email"
              name="Email"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="email" class="col-form-label">Email:</label>
              <input
                type="email"
                v-model="newEditUser_data.email"
                id="email"
                :class="{
                  'form-control': true,
                  'is-invalid': errors[0],
                }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="mb-3">
              <label for="pc-ip" class="col-form-label">PC IP:</label>
              <input
                type="text"
                v-model="newEditUser_data.pcIP"
                id="pc-ip"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="vpn-username" class="col-form-label"
                >VPN Username:</label
              >
              <input
                type="text"
                v-model="newEditUser_data.vpnUser"
                id="vpn-username"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="vpn-password" class="col-form-label"
                >VPN Password:</label
              >
              <input
                type="text"
                v-model="newEditUser_data.vpnPassword"
                id="vpn-password"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="mobilePhone" class="col-form-label"
                >Mobile Phone:</label
              >
              <input
                type="text"
                v-model="newEditUser_data.mobilePhone"
                id="mobilePhone"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="mobilePhone" class="col-form-label">Skype:</label>
              <input
                type="text"
                v-model="newEditUser_data.skype"
                id="skype"
                class="form-control"
              />
            </div>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Title"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="title" class="col-form-label">Title:</label>
              <input
                type="text"
                v-model="newEditUser_data.title"
                id="title"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Branch"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="branch" class="col-form-label">Branch:</label>
              <select
                v-model="newEditUser_data.branch"
                name="branch"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="branch in branches"
                  :key="branch.name"
                  :value="branch.name"
                >
                  {{ branch.name }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Department"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="role" class="col-form-label">Department:</label>
              <select
                v-model="newEditUser_data.department"
                name="department"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="department in departments"
                  :key="department.name"
                  :value="department.name"
                >
                  {{ department.name }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="mb-3">
              <label for="role" class="col-form-label">Permission:</label>
              <select
                v-model.number="newEditUser_data.role"
                class="form-select"
              >
                <option value="1">Administrator</option>
                <option value="2">Manager</option>
                <option value="3" selected>Standard</option>
              </select>
            </div>
            <ValidationProvider
              tag="div"
              :rules="`${
                !existingUser ? 'required|confirmed:confirmPassword' : ''
              }`"
              name="Password"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="password" class="col-form-label">Password:</label>
              <input
                type="password"
                v-model="newEditUser_data.password"
                id="password"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              vid="confirmPassword"
              :rules="`${!existingUser ? 'required' : ''}`"
              name="Confirm Password"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="confirm-password" class="col-form-label"
                >Password:</label
              >
              <input
                type="password"
                v-model="newEditUser_data.confirmPassword"
                id="confirm-password"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click.prevent="resetForm(true)"
            >
              Close
            </button>
            <button
              v-if="!existingUser"
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(createUser)"
            >
              Create User
              <loader :isLoading="isLoading"></loader>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(editUser)"
            >
              Update User
              <loader :isLoading="isLoading"></loader>
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import { Modal } from "bootstrap";
import { getters, mutations } from "../store";
export default {
  data() {
    return {
      modal: null,
      isLoading: false,
      newEditUser_data: {
        name: "",
        email: "",
        mobilePhone: "",
        skype: "",
        pcIP: "",
        vpnUser: "",
        vpnPassword: "",
        title: "",
        branch: "ALL",
        department: "",
        role: 3,
        password: "",
        confirmPassword: "",
      },
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.modal = new Modal(document.getElementById("newEditUserModal"));
  },
  computed: {
    existingUser() {
      return "_id" in this.selectedUser;
    },
    selectedUser() {
      return getters.selectedUser();
    },
    departments() {
      return getters.departments();
    },
    branches() {
      return getters.branches();
    },
    modalTitle() {
      return `${this.existingTask ? "Edit" : "New"} User`;
    },
  },
  watch: {
    selectedUser: {
      handler(selectedUser) {
        if (this.existingUser) {
          this.newEditUser_data = { ...selectedUser };
        } else {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    createUser() {
      this.isLoading = true;
      this.$axios
        .post("/users/create", this.newEditUser_data)
        .then(({ data: { success, message, createdUser } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            this.resetForm(true);
            mutations.setUser(createdUser);
          }
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    editUser() {
      this.isLoading = true;
      this.$axios
        .patch(`/users/${this.selectedUser._id}`, this.newEditUser_data)
        .then(({ data: { success, message, updatedUser } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            mutations.updateUser(updatedUser);
          }
          this.resetForm(true);
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    showResponse(response) {
      this.modal.hide();
      mutations.showToast(response);
    },
    showModal() {
      this.modal.show();
    },
    resetForm(clearUser = false) {
      this.$nextTick(() => {
        this.newEditUser_data = {
          name: "",
          email: "",
          pcIP: "",
          vpnUser: "",
          vpnPassword: "",
          title: "",
          branch: "ALL",
          department: "",
          role: 3,
          password: "",
          confirmPassword: "",
        };
        if (clearUser) {
          mutations.selectUser();
        }
        this.$refs.newEditUserForm.reset();
      });
    },
  },
};
</script>

<template>
  <div class="users">
    <div class="tile">
      <div v-if="isAdmin" class="d-flex flex-row-reverse">
        <button
          type="button"
          class="btn btn-primary mb-2"
          @click.prevent="showModal('newEditUserModal')"
        >
          <i class="fas fa-user-plus"></i> New User
        </button>
      </div>
      <vue-good-table
        ref="usersTable"
        :rows="rows"
        :columns="columns"
        :totalRows="totalCount"
        :pagination-options="{ enabled: false }"
        :group-options="{ enabled: true, collapsable: true }"
        :search-options="{ enabled: true, placeholder: 'Search' }"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'buttons'" class="text-center">
            <button
              type="button"
              class="btn btn-primary me-2"
              @click.prevent="selectUser(props.row, 'newEditUserModal')"
            >
              <i class="fas fa-user-edit"></i>
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click.prevent="selectUser(props.row, 'deleteUserModal')"
            >
              <i class="fas fa-user-minus"></i>
            </button>
          </div>
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate" class="text-center">
          <span>No data</span>
        </div>
      </vue-good-table>
      <deleteUser ref="deleteUserModal"></deleteUser>
      <newEditUser ref="newEditUserModal"></newEditUser>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import deleteUser from "../components/deleteUser";
import newEditUser from "../components/newEditUser";
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Created On",
          field: "created",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "",
          field: "buttons",
          hidden: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      return getters.users();
    },
    isAdmin() {
      return getters.isAdmin();
    },
    totalCount() {
      return getters.totalRows();
    },
  },
  watch: {
    isAdmin: {
      handler(permission) {
        this.columns[4].hidden = !permission;
      },
      immediate: true,
    },
    rows: {
      handler() {
        this.$nextTick(() => {
          this.$refs.usersTable.expandAll();
        });
      },
      immediate: true,
    },
  },
  components: {
    deleteUser,
    newEditUser,
  },
  methods: {
    selectUser(user, modalID) {
      this.showModal(modalID);
      mutations.selectUser(user);
    },
    showModal(modalID) {
      this.$refs[modalID].showModal();
    },
  },
};
</script>
